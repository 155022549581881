import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './WineSlideNavButton.module.css'

import Image from './image'

const WineSlideNavButton = ({ data, goToSlide, isActive, className }) => {
  const { bottle_image: bottleImage, title } = data.frontmatter
  return (
    <button
      onClick={goToSlide}
      className={classNames(
        styles.root,
        { [`${styles.isActive}`]: isActive },
        className
      )}
      aria-controls="wine-slider"
    >
      {bottleImage && (
        <figure className={styles.image}>
          <Image node={bottleImage} alt={title} />
          <figcaption className="visuallyhidden">Bottle of {title}</figcaption>
        </figure>
      )}
      {title}
      {isActive && <span className="visuallyhidden">(Current Slide)</span>}
    </button>
  )
}

WineSlideNavButton.propTypes = {
  data: PropTypes.object.isRequired,
  goToSlide: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
}

export default WineSlideNavButton
