import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import styles from './wineSlide.module.css'

import Image from './image'
import BuyButton from './buyButton'

class WineSlide extends Component {
  constructor(props) {
    super(props)
    this.slide = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isActive) {
      this.slide.current.focus()
    }
  }

  render() {
    const { data, tagline, className, isActive } = this.props
    const { fields, frontmatter } = data

    return (
      <article
        ref={this.slide}
        aria-roledescription="slide"
        tabIndex={-1}
        hidden={!isActive}
        className={classNames(className, styles.root, {
          [`${styles.isActive}`]: isActive,
        })}
      >
        {frontmatter.slide_image && (
          <Image
            node={frontmatter.slide_image}
            className={styles.image}
            alt={frontmatter.title}
          />
        )}
        <div className={styles.outer}>
          <div className={styles.wrapper}>
            <div className={styles.content} tabIndex={0}>
              <div className={styles.info}>
                <h2 className={styles.title}>{frontmatter.title}</h2>
                <h3 className={styles.origin}>{frontmatter.origin}</h3>
                <ul className={styles.actions}>
                  <li>
                    <BuyButton node={data} />
                  </li>
                  <li>
                    <AniLink
                      fade
                      to="/locations#locator"
                      className="button button--wide button--white"
                    >
                      Find Near Me
                    </AniLink>
                  </li>
                </ul>
                <AniLink
                  to={fields.slug}
                  paintDrip
                  hex={frontmatter.featured_color}
                  className={styles.link}
                >
                  &#9654; Learn More{' '}
                  <span className="visuallyhidden">
                    about {frontmatter.title}
                  </span>
                </AniLink>
              </div>
              <h4
                className={styles.tagline}
                dangerouslySetInnerHTML={{
                  __html: tagline.toString().replace('\n', '<br />'),
                }}
              />
            </div>
          </div>
        </div>
      </article>
    )
  }
}

WineSlide.propTypes = {
  tagline: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
}

BuyButton.defaultProps = {
  isActive: false,
}

export default WineSlide
