import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import MarkdownWithHtml from 'react-markdown/with-html'
import Fade from 'react-reveal/Fade'

import styles from './mapIntro.module.css'

import Image from './image'

export default () => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { eq: "content/location.json" }) {
          childContentJson {
            label
            title
            content
            image {
              src {
                publicURL
                childImageSharp {
                  fixed(width: 430) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
              caption
            }
          }
        }
      }
    `}
    render={data => {
      const { label, title, content, image } = data.file.childContentJson

      return (
        <section id="location" className={styles.root} aria-label={label}>
          <div className={styles.wrapper}>
            <Fade>
              <h2
                className={classNames('title', styles.title)}
                dangerouslySetInnerHTML={{
                  __html: title.toString().replace('\n', '<br />'),
                }}
              />
            </Fade>
            <div className={styles.main}>
              {content && (
                <Fade delay={250}>
                  <div className={styles.content} tabIndex={0}>
                    <MarkdownWithHtml source={content} escapeHtml={false} />
                  </div>
                </Fade>
              )}
              {image.src && (
                <Fade delay={500}>
                  <figure className={styles.figure}>
                    <Image node={image.src} alt={image.alt} />
                    {image.caption && (
                      <figcaption className="visuallyhidden">
                        {image.caption}
                      </figcaption>
                    )}
                  </figure>
                </Fade>
              )}
            </div>
          </div>
        </section>
      )
    }}
  />
)
