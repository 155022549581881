import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

import styles from './wineSlider.module.css'
import WineSlide from './wineSlide'
import WineSlideNavButton from './wineSlideNavButton'
import IconArrowPrev from '../images/icon-arrow-prev.inline.svg'

class WineSlider extends Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.state = {
      currentSlideIndex: 0,
    }
  }

  goToSlide(index) {
    this.setState({
      currentSlideIndex: index,
    })
  }

  nextSlide() {
    const nextIndex =
      this.state.currentSlideIndex === this.props.totalCount - 1
        ? 0
        : this.state.currentSlideIndex + 1
    this.goToSlide(nextIndex)
  }

  prevSlide() {
    const prevIndex =
      this.state.currentSlideIndex === 0
        ? this.props.totalCount - 1
        : this.state.currentSlideIndex - 1
    this.goToSlide(prevIndex)
  }

  keyDown = event => {
    if (this.slider.current.contains(document.activeElement)) {
      if (event.keyCode === 39) {
        this.nextSlide()
      }
      if (event.keyCode === 37) {
        this.prevSlide()
      }
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false)
  }

  render() {
    const { tagline, totalCount, wines } = this.props

    return (
      <section
        ref={this.slider}
        aria-label="Slideshow of Our Wines"
        className={styles.root}
      >
        {totalCount && (
          <>
            <div id="wine-slider" className={styles.slides}>
              {wines.map((edge, index) => (
                <WineSlide
                  tagline={tagline}
                  key={edge.node.id}
                  data={edge.node}
                  isActive={this.state.currentSlideIndex === index}
                  className={styles.slide}
                />
              ))}
            </div>
            <nav aria-label="Slideshow">
              <div className={styles.nav}>
                <ol className={styles.navList}>
                  {wines.map((edge, index) => (
                    <li key={edge.node.id}>
                      <WineSlideNavButton
                        data={edge.node}
                        isActive={this.state.currentSlideIndex === index}
                        goToSlide={this.goToSlide.bind(this, index)}
                      />
                    </li>
                  ))}
                </ol>
              </div>
              <div className={styles.arrows}>
                <button onClick={this.prevSlide.bind(this)}>
                  <span className="visuallyhidden">Previous Slide</span>
                  <IconArrowPrev />
                </button>
                <button onClick={this.nextSlide.bind(this)}>
                  <span className="visuallyhidden">Next Slide</span>
                  <IconArrowPrev />
                </button>
              </div>
            </nav>
            <div
              aria-live="polite"
              aria-atomic="true"
              className="liveregion visuallyhidden"
            >
              {`Slide ${this.state.currentSlideIndex + 1} of ${totalCount}`}
            </div>
          </>
        )}
      </section>
    )
  }
}

WineSlider.propTypes = {
  tagline: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  wines: PropTypes.array.isRequired,
}

export default ({ tagline }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "wine" } } }
          sort: { fields: [frontmatter___sort], order: ASC }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                origin
                featured_color
                bottle_image {
                  publicURL
                  childImageSharp {
                    fixed(height: 120) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                slide_image {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1680) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                purchase_links {
                  label
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { totalCount, edges } = data.allMarkdownRemark

      return (
        <WineSlider tagline={tagline} totalCount={totalCount} wines={edges} />
      )
    }}
  />
)
