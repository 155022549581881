import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import MarkdownWithHtml from 'react-markdown/with-html'
import Fade from 'react-reveal/Fade'

import styles from './symbolism.module.css'

import Image from './image'

export default () => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { eq: "content/symbolism.json" }) {
          childContentJson {
            label
            symbols {
              title
              description
              image {
                src {
                  publicURL
                  childImageSharp {
                    fixed(height: 80) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                alt
              }
            }
            image {
              src {
                publicURL
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
          }
        }
      }
    `}
    render={data => {
      const { label, symbols, image } = data.file.childContentJson

      return (
        <section id="symbolism" className={styles.root} aria-label={label}>
          <div className={styles.wrapper}>
            <Fade delay={250}>
              <Tabs className={styles.content}>
                <TabList className={styles.tabList}>
                  {symbols.map((symbol, index) => (
                    <Tab key={index} className={styles.tab}>
                      <Image
                        node={symbol.image.src}
                        alt={symbol.image.alt}
                        className={styles.tabImage}
                      />
                      <span className={styles.tabTitle}>{symbol.title}</span>
                    </Tab>
                  ))}
                </TabList>
                <TabPanels className={styles.tabPanel}>
                  {symbols.map((symbol, index) => (
                    <TabPanel key={index}>
                      <MarkdownWithHtml
                        source={symbol.description}
                        escapeHtml={false}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Fade>
            {image && (
              <Fade delay={500}>
                <figure className={styles.figure}>
                  <Image
                    node={image.src}
                    alt={image.alt}
                    className="framed-image"
                  />
                </figure>
              </Fade>
            )}
          </div>
        </section>
      )
    }}
  />
)
