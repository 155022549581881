import React from 'react'
import { graphql } from 'gatsby'

import { useSiteMetadata } from '../hooks/useSiteMetadata'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WineSlider from '../components/wineSlider'
import MapIntro from '../components/mapIntro'
import Symbolism from '../components/symbolism'
import Society from '../components/society'
import Social from '../components/social'

export default ({ data }) => {
  const { title, tagline } = useSiteMetadata()
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} seoTitle={frontmatter.seo_title} />
      <h1 className="visuallyhidden">{title}</h1>
      <WineSlider tagline={tagline} />
      <MapIntro />
      <Symbolism />
      <Society />
      <Social />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        seo_title
        headline
      }
    }
  }
`
